import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import { EditorState } from 'draft-js'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import RichTextEditor from '../../components/richTextEditor'
import PdfItem from '../../components/pdfItem/'
import { convertFromRaw, convertToRaw } from 'draft-js'
import { pipe } from 'ramda'
import { create, update, getById } from '../../reducers/actions/project.js'
import * as styles from './styles.module.css'
import withLocation from '../../HOC/withLocation.js'
import moment from 'moment'

const Attachments = ({attachments}) => (
  <section>
    <button>Adjuntar archivos</button>
    <div className={styles.attachmentsList}>
    {attachments.map(index => (
      <div key={index}>
        <PdfItem filename={'ejemplo.pdf'}/>
      </div>
    ))}
    </div>
  </section>
)

const NewProjectPage = ({search}) => {
  
  const {userReducer, projectReducer } = useSelector(state => state)
  const dispatch = useDispatch()

  const id = search.id
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [project, setProject] = useState({
    name: '',
    summary: '',
    details: EditorState.createEmpty(),
    budget: '',
    deadline: '',
    attachments: [],
    user: '',
    status: 'open'
  })


  useEffect(() => {
    if (id && isFirstRender) {
      setIsFirstRender(false)
      getById(dispatch)(id)
    }
  
    if (userReducer?.login?.response) {
      setProject({ ...project, user: userReducer.login.response._id })
    }
  
    if (projectReducer?.getProjectById?.response) {
      const projectRegistered = projectReducer.getProjectById.response
      delete projectRegistered.__v
      const { details } = projectRegistered
  
      // Verificar si 'details' tiene un valor válido antes de convertirlo
      if (details) {
        try {
          projectRegistered.details = EditorState.createWithContent(convertFromRaw(JSON.parse(details)))
        } catch (error) {
          console.error("Error parsing details:", error)
          projectRegistered.details = EditorState.createEmpty() // Si hay un error al parsear, usa el valor vacío
        }
      } else {
        projectRegistered.details = EditorState.createEmpty() // Si no hay 'details', usa el valor vacío
      }
  
      setProject(projectRegistered)
    }
  }, [userReducer?.login, projectReducer?.getProjectById])
  

  useEffect(() => {
    const create = projectReducer.createProject.response
    const update = projectReducer.updateProject.response
    if(
      (create && create.publish) || 
      (update && update.publish)
    ){

      navigate('/profile', {state: {tab: 'Mis Proyectos'}})
    }
  }, [projectReducer.updateProject, projectReducer.createProject])

  const setInputChanges = event => setProject({...project, [event.target.name]: event.target.value}) 
  const onChangeDetails = editorState => setProject({...project, details: editorState})
  
  const setBudget = pipe(
    event => event.target.value,
    budget => budget.replace(/\D/g,''),
    budget => setProject({...project, budget})
  )

  const dottedNumber = number => number.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  const setDeadline = event => setProject({...project, deadline: event.target.value})
  const save = () => {
    const details = JSON.stringify(convertToRaw(project.details.getCurrentContent()))
    const payload = {...project, details}
    const createResponse = projectReducer.createProject.response
    if(!createResponse && !id) {
      create(dispatch)(payload)
    } else {
      payload._id = createResponse ? createResponse._id : id
      update(dispatch)(payload)
    }
  }

  const publish = () => {
    const details = JSON.stringify(convertToRaw(project.details.getCurrentContent()))
    const payload = {...project, details, status: 'open', publish: true}
    const createResponse = projectReducer.createProject.response
    if(!createResponse && !id) {
      create(dispatch)(payload)
    } else {
      payload._id = createResponse ? createResponse._id : id
      update(dispatch)(payload)
    }
  }

  return (
    <Layout>
      <SEO title='Proyectos' />
      <h1>Proyectos</h1>
      <div className={styles.form}>
        <div>
          <input name='name' value={project.name} onChange={setInputChanges} placeholder={'Nombre del proyecto'}/>
        </div>

        <label>
          Resumen
        </label>
        <textarea name='summary' value={project.summary} onChange={setInputChanges} rows={5}/>

        <label>
          Detalles
        </label>
        <div className={styles.richTextEditor}>
          <RichTextEditor editorState={project.details} onChangeEditor={onChangeDetails} />
        </div>

        <label >
          Presupuesto
        </label>
        <input name= "budget" value={dottedNumber(project.budget)} onChange={setBudget} className={styles.budget} />
        
        <label>
          Cierre de postulaciones
        </label>
        <input type='date' name='trip-start' value={moment(project.deadline).format('YYYY-MM-DD')} onChange={setDeadline} min='2020-01-01' className={styles.deadline} />
        <div className={styles.attachments}>
          <Attachments attachments={project.attachments} />
        </div>
        <div className={styles.actions}>
          <button onClick={save}>Guardar</button>
          <button onClick={publish}>Crear proyecto</button>
        </div>
      </div>
    </Layout>
  )
}
export default withLocation(NewProjectPage)
